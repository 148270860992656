.homewrapper{
    /* border: 1px solid purple;
    box-sizing: border-box; */
    display: flex;
    max-width: 800px;
    /* width: 50%; */
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.chosewrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textwrapper{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    max-width: 800px;
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: min-content;
}

.heading{
    font-weight: 800;
    color:var(--text-color);
}  

.body{
    font-size: 30%;
    font-weight: 500;
    text-align: center;
    color: var(--home-text-color);
    padding-bottom: 1rem;

}

.bodyspan{
    font-weight: 800;
    color: var(--text-color);
}

.inputwrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.codeinput{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 8px 15px;
    border: 2px solid var(--thin-border-color);
    background-color: var(--secondary-color);
    color: var(--text-color);
    width: 100%;
    text-align: center;
    font-size: 1rem;
}

.codeinput:focus{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    box-shadow: none;
}

.codeinput:active{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    box-shadow: none;
}

.divbutton{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 8px 15px;
    background-color: var(--text-grey-color);
    border: 2px solid var(--thin-border-color);
    color: var(--text-color);
    width: 100%;
    text-align: center;
    font-size: 1rem;
}

.divbutton:hover{
    cursor: pointer
}

@media screen and (max-width: 480px) {
    .textwrapper {
      font-size: 11.8vw;
    }
  }