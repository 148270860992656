.textinputwrapper{
    /* box-sizing: border-box;
    border: 1px solid green; */
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: env(safe-area-inset-bottom);
    padding: 10px 0px 10px 0px;
    background-color: var(--backdrop-blur-color);
    backdrop-filter: blur(20px);
    position: absolute;
    bottom: 0;
    width: 100%;
}

.typefield{
    /* box-sizing: border-box;
    border: 1px solid purple; */
    width: 90%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputelement {
    width: 100%;
    overflow: hidden;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    resize: none;
    appearance: none;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 20px;
    border: 2px solid var(--thin-border-color);
    background: transparent;
    color: var(--text-color);
    font-size: 1rem;
    padding: 8px 15px;
    padding-right: 35px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    min-height: 40px;
  }

.inputelement:focus{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid var(--thin-border-color);
    outline: none;
    box-shadow: none;
}

.inputelement:active{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #4C4C4C;
    outline: none;
    box-shadow: none;
}

.sendbutton{
    /* box-sizing: border-box;
    border: 1px solid yellow; */
    position: absolute;
    height: 100%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrowicon{
    /* box-sizing: border-box;
    border: 1px solid blue; */
    font-size: calc(1rem + 16px);
    position: relative;
    color: var(--send-message-icon-color);
    display: flex;
    justify-content: center;
    align-items: center;
    /* justify-self: flex-start; */
    align-self: flex-end;
    padding-right: 4px;
    padding-bottom: 4px;
}

.arrowicon > svg{
    position: relative;
    z-index: 2;
}

.arrowicon::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 60%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1;
}