.chatdetailswrapper{
    /* box-sizing: border-box;
    border: 1px solid blue; */
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
}

.chatdetailscontainer{
    /* box-sizing: border-box;
    border: 1px solid red; */
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 0rem;
}

.informationtoggle{
    /* box-sizing: border-box;
    border: 1px solid yellow; */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    color: var(--text-color);
    padding-left: 8px;
}

.informationtoggle:hover{
    cursor: pointer;
}

.iconswrapper{
    /* box-sizing: border-box;
    border: 1px solid blue; */
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.usericon{
    /* box-sizing: border-box;
    border: 1px solid red; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--text-color);
    align-self: center;
    background: rgb(78,78,78);
    background: linear-gradient(0deg, var(--user-icon-darker-shade) 44%, var(--user-icon-lighter-shade) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    align-self: flex-end;
}

.usericonmediator{
    background: linear-gradient(0deg, var(--mediator-icon-darker-shade) 44%, var(--mediator-icon-lighter-shade) 100%);
    width: 3rem;
}

.sessioniddisplaynamewrapper{
    /* box-sizing: border-box;
    border: 1px solid white; */
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    padding-right: 8px;
}

.sessionid{
    /* box-sizing: border-box;
    border: 1px solid yellow; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--text-color);
    font-weight: 500;
}

.displayname {
    /* box-sizing: border-box;
    border: 1px solid green; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--text-color);
    font-weight: 500;
}

