.App {
  /* border: 1px solid blue; */
  text-align: center;
  display: flex;
  justify-content: center;
  /* justify-content: space-around; */
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100vw;
  background-color: var(--secondary-color);
  position: relative;
}
.temp{
  /* width: 50%; */
  box-sizing: border-box;
  border: 1px solid red;
  height: 100vh;
  background-color: white
}

html{
  background-color: var(--secondary-color);
}

body{
  background-color: var(--secondary-color);
}

#root{
  background-color: var(--secondary-color);
}

/* @media only screen and (max-width: 700px){
  .temp{
    display: none;
  }
} */

@media only screen and (max-width: 700px){
  .App{
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
  }

}

.App::-webkit-scrollbar {
  width: 0.5em;
}

.App::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.App {
  scrollbar-width: none;
}