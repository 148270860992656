.entersessionidwrapper{
    box-sizing: border-box;
    /* border: 1px solid red; */
    max-width: 800px;
    /* width: 50%; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--text-color);
}

.heading{
    box-sizing: border-box;
    /* border: 1px solid green; */
    font-size: 1.3rem;
    padding-bottom: 1rem;
}

.headingbold{
    font-weight: 800;
}

.headingbold:hover{
    cursor: pointer;
}

.codeinput{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 8px 15px;
    border: 2px solid var(--thin-border-color);
    background-color: var(--secondary-color);
    color: var(--text-color);
    width: 100%;
    text-align: center;
    font-size: 1rem;
    transition: border 0.5s linear
}

.codeinputerror{
    border: 2px solid red;
    transition: none;
    animation: shake 0.1s;
}

.errormessagewrapper{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    height: 1rem;
    color: red;
}

@keyframes shake{
    0% {
        transform: translateX(-5px)
    }
    10% {
        transform: translateX(5px)
    }
    20% {
        transform: translateX(-5px)
    }
    30% {
        transform: translateX(5px)
    }
    40% {
        transform: translateX(-5px)
    }
    50% {
        transform: translateX(5px)
    }
    60% {
        transform: translateX(-5px)
    }
    70% {
        transform: translateX(5px)
    }
    80% {
        transform: translateX(-5px)
    }
    90% {
        transform: translateX(5px)
    }
    100% {
        transform: translateX(-5px)
    }
}