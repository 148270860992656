.aboutwrapper{
    box-sizing: border-box;
    /* border: 1px solid red; */
    height: 100vh;
    width: calc(100%);
    border-right: 2px solid var(--backdrop-color);
    background-color: var(--secondary-color);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* font-size: calc(calc(100vw - 700px) * 0.07); */
    font-size: 6.5vh;
    display: var(--information-display);
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    --spacing: 1.8vh;
}

.heading{
    font-weight: 800;
    color: var(--text-color);
    padding: var(--spacing) 0;
}

.subheading{
    box-sizing: border-box;
    /* border: 1px solid blue; */
    font-weight: 700;
    font-size: 40%;
    text-align: left;
    color: var(--text-color);
    width: 90%;
    padding-bottom: var(--spacing);
}

.body{
    box-sizing: border-box;
    /* border: 1px solid red; */
    font-size: 30%;
    font-weight: 500;
    text-align: center;
    text-align: left;
    color: var(--home-text-color);
    padding-bottom: var(--spacing);
    width: 90%;
}

ul{
    text-align: left;
    margin: 0;
    padding-left: 10%;
}

li{
    padding-bottom: var(--spacing);
}

.colorSchemeSpan:hover{
    cursor: pointer;
}

.link{
    text-decoration: none;
    box-sizing: border-box;
    font-weight: 700;
    text-align: left;
    color: var(--text-color);
    width: 90%;
    padding-bottom: var(--spacing);
}


@media only screen and (max-width: 1000px){
    .aboutwrapper{
        margin-top: 4rem;
        position: absolute;
        z-index: 1;
        font-size: 8vw;
    }
}